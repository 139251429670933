// change the theme
$theme-colors: (
  "primary": #00AB4E,
  "secondary": #666,
  "warning": #ffc107,
  "danger": #bb2d3b
);
$grid-gutter-width: 3rem;

// $body-bg: #000000;
$body-color: #333;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

@font-face {
  font-family: AvenirNext;
  src: url(./font/AvenirNext-Regular.ttf);
}


body {
  font-family: "AvenirNext", sans-serif;
}

input:-webkit-autofill::first-line {
  font-family: "AvenirNext", sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  font-family: "AvenirNext", sans-serif;
}

.btn {
  color: #fff;
  &:hover {
    color: #eee;
  }
}

.btn-link {
  color: #0d6efd;
  padding: 0;
  border: none;
  &:hover {
    color: #007bff;
  }
}

// a {
//   color: #ffffff;
// }

// a:hover {
//   color: #ffffff;
// }

.table td.fit, 
.table th.fit {
    white-space: nowrap;
    width: 1%;
}
