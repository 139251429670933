.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.sidebar-brand-text {
  font-size: 0.8rem;
  text-align: left;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.chartTitle {
  text-align: center;
  font-size: 1.6em;
  margin-bottom: 0;
}

.visualImage {
  /* text-align: center; */
  margin-bottom: 20px;
}

.visualImage img {
  max-height: 400px;
}


.nav-stacked>li {
  float: none;
  width: 100%;
}
.nav-pills>li {
  float: left;
}
.nav>li {
  position: relative;
  display: block;
  text-align: center;
  margin-bottom: 10px;
}
.nav-pills>li.active>a, .nav-pills>li.active>a:focus, .nav-pills>li.active>a:hover {
  color: #fff;
  background-color: #00AB4E;
}
.nav-pills>li>a {
  border-radius: 4px;
  text-decoration: none !important;
  border: 1px solid #00AB4E;
  border-radius: 999px;
}
.nav>li>a {
  position: relative;
  display: block;
  padding: 10px 15px;
  color: #00AB4E;
}

div.rt-td {
  align-self: center;
}

.loginLogo {
  max-width: 200px;
  margin-bottom: 20px;
}